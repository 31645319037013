<template>
  <div class="flex flex-col gap-1">
    <div>
      <fw-label size="xs">Cenários de vídeo</fw-label>
      <div class="relative flex flex-col gap-4 bg-gray-900 bg-opacity-90 rounded-lg p-3">
        <div v-if="obsServer['main'].isConnected" class="flex flex-col gap-4">
          <div class="grid grid-cols-3 gap-4">
            <fw-button
              v-for="scene in obsServer['main'].scenes"
              :key="scene.name"
              :type="obsServer['main'].currentScene === scene.name ? 'link' : 'link-muted'"
              expanded
              size="xl"
              class="h-[4rem] text-2xl"
              :class="{ 'text-gray-400': obsServer['main'].currentScene !== scene.name }"
              @click.native="switchScene(obsServer['main'], scene.name)"
            >
              {{ scene.label }}
            </fw-button>
          </div>
        </div>
        <div v-else class="text-base text-center py-3 text-gray-600 flex flex-col gap-1">
          <div>Desligado</div>
          <div v-if="obsServer['main'].error" class="text-xs text-red-600 opacity-80">
            O serviço não está disponível.
          </div>
        </div>
      </div>
    </div>
    <div v-if="usePresentationScreen">
      <fw-label size="xs">Controlo de ecrã principal</fw-label>
      <div class="relative flex flex-col gap-4 bg-gray-900 bg-opacity-90 rounded-lg p-3">
        <div v-if="obsServer['presentation'].isConnected" class="flex flex-col gap-4">
          <div class="grid grid-cols-3 gap-4">
            <fw-button
              v-for="scene in obsServer['presentation'].scenes"
              :key="scene.name"
              :type="obsServer['presentation'].currentScene === scene.name ? 'link' : 'link-muted'"
              expanded
              size="xl"
              :class="{ 'text-gray-400': obsServer['presentation'].currentScene !== scene.name }"
              @click.native="switchScene(obsServer['presentation'], scene.name)"
            >
              {{ scene.label }}
            </fw-button>
          </div>
        </div>
        <div v-else class="text-base text-center py-3 text-gray-600 flex flex-col gap-1">
          <div>Desligado</div>
          <div v-if="obsServer['presentation'].error" class="text-xs text-red-600 opacity-80">
            O serviço não está disponível.
          </div>
        </div>
      </div>
    </div>
    <div v-if="obsServer['main'].isConnected">
      <fw-label size="xs">Gravação</fw-label>
      <div>
        <ButtonTablet
          :color="obsServer['main'].isRecording ? 'red' : 'inactive'"
          class="text-base px-5 py-1 h-[3.5rem]"
          @click.native="toggleRecording"
        >
          <div
            class="flex gap-1 w-full"
            :class="{
              'text-white': obsServer['main'].isRecording,
              'text-gray-400': !obsServer['main'].isRecording
            }"
          >
            <fw-icon-record-circle
              class="w-6 h-6 flex-shrink-0"
              :class="{ 'animate-pulse': obsServer['main'].isRecording }"
            />
            <span class="w-20" :class="{ 'text-left': obsServer['main'].isRecording }">
              {{
                obsServer['main'].isRecording
                  ? obsServer['main'].recordingCounter
                    ? formattedRecordingCounter
                    : 'A gravar'
                  : 'Gravar'
              }}
            </span>
          </div>
        </ButtonTablet>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonTablet from '@/components/buttons/ButtonTablet'

export default {
  name: 'PanelObsControl',

  components: {
    ButtonTablet
  },

  data() {
    return {
      obsServer: {
        main: {
          name: 'main',
          ws: null,
          isConnected: false,
          error: false,
          config: {
            address: 'localhost',
            password: localStorage.getItem('obs-main-server-password') || null
          },
          isVirtualCamOn: false,
          scenes: [
            { name: 'Main', label: 'Principal' },
            { name: 'Screen', label: 'Partilha' },
            { name: 'Audience', label: 'Plateia' }
          ],
          currentScene: null,
          isRecording: false,
          recordingCounter: 0,
          recordingInterval: null
        },
        presentation: {
          name: 'presentation',
          ws: null,
          isConnected: false,
          error: false,
          config: {
            address: localStorage.getItem('obs-presentation-server-address') || null,
            password: localStorage.getItem('obs-presentation-server-password') || null
          },
          scenes: [
            { name: 'Hall', label: 'Hall' },
            { name: 'Screen', label: 'Partilha' }
          ],
          currentScene: null
        }
      },
      usePresentationScreen: localStorage.getItem('obs-use-presentation-screen') === 'true'
    }
  },

  computed: {
    formattedRecordingCounter() {
      const counter = this.obsServer['main'].recordingCounter
      let hours = Math.floor(counter / 3600)
      let minutes = Math.floor((counter % 3600) / 60)
      let seconds = counter % 60
      return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`
    }
  },

  onDestroy() {
    this.obsServer['main'].ws.close()
    this.obsServer['main'].ws = null

    if (this.usePresentationScreen) {
      this.obsServer['presentation'].ws.close()
      this.obsServer['presentation'].ws = null
    }
  },

  mounted() {
    if (this.usePresentationScreen) {
      this.connectToObs('presentation')
    }
    this.connectToObs('main')
  },

  methods: {
    connectToObs(obsName) {
      var self = this
      var obs = this.obsServer[obsName]

      obs.ws = new WebSocket(`ws://${obs.config.address}:4455`)

      obs.ws.onopen = () => {
        obs.isConnected = true
        // self.wsAuthenticate(obs)
      }

      obs.ws.onmessage = message => {
        self.wsHandleMessage(obs, JSON.parse(message.data))
      }

      obs.ws.onerror = error => {
        console.error('WebSocket error:', error)
        obs.error = true
        obs.isConnected = false
      }

      obs.ws.onclose = event => {
        console.log('WebSocket closed:', event.reason, event.code)
        obs.error = true
        obs.isConnected = false
      }
    },

    async sha256Hash(inputText) {
      const utf8 = new TextEncoder().encode(inputText)
      const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
      const hashArray = Array.from(new Uint8Array(hashBuffer))
      const base64Hash = btoa(String.fromCharCode(...hashArray))
      return base64Hash
    },

    wsSendMessage(obs, message) {
      if (obs.ws && obs.ws.readyState === WebSocket.OPEN) {
        obs.ws.send(JSON.stringify(message))
      } else {
        console.error('WebSocket is not open', obs)
      }
    },

    async wsHandleMessage(obs, message) {
      console.log('Received OBS WS Message:', obs, message)

      // Initial request
      if (message.op === 0) {
        // Hello
        const authChallenge = message.d.authentication
        if (authChallenge) {
          const salt = authChallenge.salt
          const challenge = authChallenge.challenge

          const secret = await this.sha256Hash(obs.config.password + salt)
          const authResponseHash = await this.sha256Hash(secret + challenge)

          this.wsSendMessage(obs, {
            op: 1,
            d: {
              rpcVersion: 1,
              authentication: authResponseHash
            }
          })
        }
      }

      // Authentication is a success!
      else if (message.op === 2) {
        if (obs.name === 'main') {
          this.getVirtualCamStatus() // If not active, start it in the next message
          this.getRecordingStatus()
          // this.stopRecording() // Make sure recording is stopped
          // this.switchScene(obs, obs.defaultScene) // Set initial scene
        }
        this.getCurrentScene(obs)
      }

      if (message.op === 22) {
        // Handle GetRecordStatus response
        debugger
        const elapsedTime = message.d.elapsedTime // Elapsed time in seconds
        console.log('Elapsed Time:', elapsedTime)
      }

      // Events
      // Set scene when changed on OBS
      else if (message.d?.eventType === 'CurrentProgramSceneChanged' && message.d?.eventData) {
        obs.currentScene = message.d.eventData.sceneName
      }
      // Is recording
      else if (message.d?.eventType === 'RecordStateChanged' && message.d?.eventData) {
        obs.isRecording = message.d.eventData.outputActive
        if (obs.isRecording) {
          this.startRecordingCounter()
        } else {
          this.stopRecordingCounter()
        }
      }

      // Request responses
      // Get current scene on OBS (initial)
      else if (message.d?.requestType === 'GetCurrentProgramScene' && message.d?.responseData) {
        obs.currentScene = message.d.responseData.currentProgramSceneName
      }
      // Get recording status
      else if (message.d?.requestType === 'GetRecordStatus' && message.d?.responseData) {
        obs.isRecording = message.d.responseData.outputActive
      }
      // Get virtual cam
      else if (message.d?.requestType === 'GetVirtualCamStatus' && message.d?.responseData) {
        if (!message.d.responseData.outputActive) {
          this.startVirtualCam()
        }
      }
    },

    // Scenes
    getCurrentScene(obs) {
      this.wsSendMessage(obs, {
        op: 6,
        d: {
          requestType: 'GetCurrentProgramScene',
          requestId: 'getCurrentScene'
        }
      })
      // Expect a response in wsHandleMessage
    },

    switchScene(obs, sceneName) {
      this.wsSendMessage(obs, {
        op: 6,
        d: {
          requestType: 'SetCurrentProgramScene',
          requestId: 'switchScene',
          requestData: { sceneName }
        }
      })
      obs.currentScene = sceneName
    },

    // Recording
    toggleRecording() {
      if (this.obsServer['main'].isRecording) {
        this.stopRecording()
        // Avoid user to quit or leave before ending the recording
        this.$emit('recording-running', false)
      } else {
        this.$buefy.dialog.confirm({
          title: 'Gravação',
          message: `A gravação irá incluir o vídeo transmitido pela UC DigitalDesk, de acordo com a composição
            do cenário escolhido, o áudio de todos os participantes (da sala e remotos).<br /><br />
            No final da sua sessão, por favor, solicite a gravação à nossa equipa de suporte.`,
          confirmText: 'Iniciar gravação',
          cancelText: 'Cancelar',
          type: 'is-danger',
          onConfirm: () => {
            this.startRecording()
            // Avoid user to quit or leave before ending the recording
            this.$emit('recording-running', true)
          }
        })
      }
    },

    stopRecording() {
      this.wsSendMessage(this.obsServer['main'], {
        op: 6,
        d: {
          requestType: 'StopRecord',
          requestId: 'stop_record'
        }
      })
      this.obsServer['main'].isRecording = false
    },

    startRecording() {
      this.wsSendMessage(this.obsServer['main'], {
        op: 6,
        d: {
          requestType: 'StartRecord',
          requestId: 'start_record'
        }
      })
    },

    getRecordingStatus() {
      this.wsSendMessage(this.obsServer['main'], {
        op: 6,
        d: {
          requestType: 'GetRecordStatus',
          requestId: 'getRecordingStatus'
        }
      })
      // Expect a response in wsHandleMessage
    },

    startRecordingCounter() {
      var interval = this.obsServer['main'].recordingInterval
      if (interval) {
        clearInterval(interval)
      }

      // Start the counter (make sure is 0)
      this.obsServer['main'].recordingCounter = 0

      interval = setInterval(() => {
        this.obsServer['main'].recordingCounter++
      }, 1000)
    },

    // Stop the counter
    stopRecordingCounter() {
      var interval = this.obsServer['main'].recordingInterval
      if (interval) {
        clearInterval(interval)
        interval = null
      }

      // Reset the counter
      this.obsServer['main'].recordingCounter = 0
    },

    // Virtual Cam
    getVirtualCamStatus() {
      this.wsSendMessage(this.obsServer['main'], {
        op: 6,
        d: {
          requestType: 'GetVirtualCamStatus',
          requestId: 'getVirtualCamStatus'
        }
      })
      // Expect a response in wsHandleMessage
    },

    startVirtualCam() {
      this.wsSendMessage(this.obsServer['main'], {
        op: 6,
        d: {
          requestType: 'StartVirtualCam',
          requestId: 'start_virtual_cam'
        }
      })
      this.obsServer['main'].isVirtualCamOn = true
    }
  }
}
</script>

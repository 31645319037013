<template>
  <fw-modal :active.sync="isActive" :can-cancel="true" size="full" width="28rem" @close="closeModal">
    <div class="flex flex-col sm:flex-row gap-10 w-full">
      <div class="flex-1 sm:w-64 flex flex-col gap-3">
        <fw-heading size="h2">Entrar numa sala</fw-heading>
        <fw-label>Indique o Id da sala virtual</fw-label>
        <form class="flex flex-col gap-3" @submit.prevent="checkEntryAndGo">
          <div>
            <b-input size="is-medium" id="entrykey" v-model="entryKeyToJoin" placeholder="Id da sala virtual" />
          </div>
          <div
            v-if="errorMessage"
            class="text-center py-3 px-2 bg-red-500 bg-opacity-20 rounded-lg text-red-800 text-sm font-bold"
          >
            {{ errorMessage }}
          </div>
          <div>
            <fw-button
              type="black"
              size="lg"
              :disabled="!entryKeyToJoin || loadingCheckEntry"
              :loading="loadingCheckEntry"
              expanded
              @click.native="checkEntryAndGo"
              >Entrar</fw-button
            >
          </div>
        </form>
        <BlockVirtualKeyboard v-if="false" key="keyboard" :input="entryKeyToJoin" @onChange="onChangeKeyboard" />
      </div>
    </div>
  </fw-modal>
</template>

<script>
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import BlockVirtualKeyboard from '@/fw-modules/fw-core-vue/ui/components/blocks/BlockVirtualKeyboard.vue'

export default {
  components: { BlockVirtualKeyboard },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isActive: this.show || false,
      entryKeyToJoin: null,
      latestEntriesJoined: [],
      duplicatedKeys: [],
      errorMessage: null,
      loadingCheckEntry: false
    }
  },

  computed: {
    loggedUser() {
      return this.$store.getters.getUser
    },
    isMobile() {
      return this.window.width < 640
    }
  },

  watch: {
    show(value) {
      this.isActive = value
    }
  },

  methods: {
    onChangeKeyboard(input) {
      this.entryKeyToJoin = input
    },
    closeModal() {
      this.isActive = false
      this.entryKeyToJoin = null
      this.$emit('close')
    },

    async findMeeting(key) {
      try {
        const meeting = await ServiceMeetings.getMeeting(key)
        return {
          type: 'meeting',
          key: meeting.key,
          title: meeting.title,
          key_alias: meeting.key_alias,
          item_type: meeting.item_type
        }
      } catch {
        console.debug(`Failed to find meeting ${key}`)
      }
    },
    async findEntryAndGo(key, type = null) {
      if (!key) return

      this.loadingCheckEntry = true
      this.duplicatedKeys = []
      this.errorMessage = null

      try {
        const futures = []
        if (!type || type == 'meeting') {
          futures.push(this.findMeeting(key))
        }

        const entries = []
        if (futures.length) {
          for (let entry of await Promise.all(futures)) {
            if (entry) {
              entries.push(entry)
            }
          }
        }

        if (!entries.length) {
          // Remove from history
          let previousLength = this.latestEntriesJoined.length
          for (let i = previousLength - 1; i >= 0; i--) {
            if (this.latestEntriesJoined[i].key == key) {
              this.latestEntriesJoined.splice(i, 1)
            }
          }

          this.errorMessage = 'O Id da sala virtual não foi encontrado.'
        } else if (entries.length > 1) {
          this.duplicatedKeys = entries
        } else {
          this.goToEntry(entries[0])
        }
      } finally {
        this.loadingCheckEntry = false
      }
    },
    goToEntry(entry) {
      // Check if exists - if not, add to history
      if (entry.type !== 'meeting' || entry.key != this.loggedUser.meeting.key) {
        let exists = false
        for (let historyEntry of this.latestEntriesJoined) {
          if (historyEntry.type === entry.type && historyEntry.key === entry.key) {
            historyEntry.title = entry.title
            historyEntry.key_alias = entry.key_alias
            historyEntry.item_type = entry.item_type
            exists = true
            break
          }
        }
        if (!exists) {
          this.latestEntriesJoined.push(entry)
        }
      }

      // Go to entry hall
      if (entry.type == 'exam') {
        this.$router.push({ name: 'exams-v1-join', params: { key: entry.key } })
      } else {
        this.$router.push({ name: 'live', params: { key: entry.key_alias || entry.key } })
      }
    },
    async checkEntryAndGo() {
      if (this.entryKeyToJoin) await this.findEntryAndGo(this.entryKeyToJoin)
    }
  }
}
</script>

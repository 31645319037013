<template>
  <div class="w-full h-full flex justify-between bg-gray-50">
    <!-- Chat list -->
    <div
      v-show="!isEmpty && !chatMeetingOnly && (!isMobile || (isMobile && mobileShowChatsList))"
      class="w-full md:w-1/3 lg:w-1/4 xl:w-1/5 2xl:w-1/6 h-full flex flex-col md:border-r border-gray-200 relative"
      :style="!isMobile ? 'max-width: 300px' : ''"
    >
      <div v-if="show_archive" class="pl-4 pr-3 pt-4 pb-2.5 flex items-center justify-start relative gap-2.5">
        <fw-button type="transparent" @click.native="show_archive = false">
          <fw-icon-chevron-left class="h-5 w-5" />
        </fw-button>
        <fw-heading size="h3"> Canais arquivados </fw-heading>
      </div>
      <div v-else class="pl-5 pr-3 pt-4 pb-2.5 flex items-center justify-between relative">
        <fw-heading size="h3">
          {{ panelForGroups ? 'Grupos' : 'Canais' }}
          <fw-badge inline type="simple">
            {{ chatPagination ? chatPagination.total_items : activeChats ? activeChats.length : 0 }}
          </fw-badge>
        </fw-heading>
        <div class="flex items-center gap-0">
          <fw-button v-if="chats.length > 1" type="transparent" @click.native="openChannelSearch()">
            <fw-icon-search class="h-5 w-5" />
          </fw-button>
          <b-dropdown v-if="addAllowed || panelForGroups" aria-role="list" position="is-bottom-left">
            <template #trigger>
              <fw-button size="sm" type="transparent">
                <fw-icon-add class="w-5 h-5" />
              </fw-button>
            </template>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button
                v-if="!panelForGroups"
                type="dropdown-item"
                class="w-full"
                label="Criar canal pessoal"
                @click.native="addNewChannelModal"
              >
                Criar canal pessoal
              </fw-button>
            </b-dropdown-item>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button type="dropdown-item" class="w-full" label="Criar grupo" @click.native="addNewGroupModal">
                Criar grupo
              </fw-button>
            </b-dropdown-item>
          </b-dropdown>
          <fw-menu-more>
            <b-dropdown-item paddingless aria-role="listitem">
              <fw-button
                type="dropdown-item"
                class="w-full"
                label="Canais arquivados"
                @click.native="showArchivedChats()"
              >
                {{ show_archive ? 'Ver canais ativos' : 'Ver canais arquivados' }}
              </fw-button>
            </b-dropdown-item>
          </fw-menu-more>
        </div>
        <div v-if="chatSearchOpen" class="absolute left-0 top-0 right-0 px-4 py-4 bg-gray-50">
          <div class="relative bg-gray-200 flex rounded-full overflow-hidden">
            <fw-icon-search class="h-5 w-5 absolute left-5 top-2 pointer-events-none opacity-50" />
            <input
              ref="chatSearchInput"
              v-model="chatSearch"
              class="chat-search flex-1 outline-none pl-14 py-1 pr-3 bg-transparent"
              placeholder="Pesquisar canais..."
            />
            <fw-button type="transparent" @click.native="closeChannelSearch()">
              <fw-icon-close class="h-5 w-5 opacity-50" />
            </fw-button>
          </div>
        </div>
        <div v-if="chatFilters && chatFilters.length">
          <button
            v-for="chatFilter in chatFilters"
            :key="chatFilter.type"
            :class="{ 'is-active': chatFilter.type === activeFilter }"
            class="button is-text is-small"
            @click="toggleFilter(chatFilter.type)"
          >
            {{ chatFilter.title }}
          </button>
        </div>
      </div>
      <div v-if="activeChats && activeChats.length" class="flex flex-1 flex-col gap-2 overflow-y-auto">
        <RecycleScroller
          v-slot="{ item }"
          :items="activeChats"
          :item-size="55"
          :buffer="50"
          key-field="key"
          class="px-4"
          @scroll-end="loadMoreChats"
        >
          <button
            class="flex p-2 gap-2 relative items-center w-full text-left group rounded-lg my-1"
            :class="{
              'text-primary bg-white': item.key == chatActive.key,
              'text-gray-600 bg-gray-200 bg-opacity-30 hover:text-gray-800': item.key != chatActive.key
            }"
            @click="loadChat(item)"
          >
            <fw-avatar v-if="item.type == 'group'" :group="item" size="sm" class="w-10 h-10" animated />
            <fw-avatar v-else-if="item.type == 'user'" :user="buildChatUser(item)" size="sm" animated />
            <fw-avatar v-else icon="people" size="sm" class="w-10 h-10" animated />
            <div class="flex-1">
              <div class="font-semibold">
                <div
                  v-if="meeting && meeting.chat && meeting.chat.key == item.key"
                  class="flex items-center justify-between"
                >
                  Canal da sala <fw-icon-live class="w-5 h-5" />
                </div>
                <div v-else class="flex gap-2">
                  <v-clamp
                    autoresize
                    :max-lines="1"
                    :class="{ 'font-bold': buildUnread(item.unread) || item.key == chatActive.key }"
                    >{{ item.title }}</v-clamp
                  >
                  <div
                    v-if="item.context && (item.context.academic_year || item.context.tag)"
                    class="text-xs opacity-70 mt-1"
                  >
                    {{ item.context.academic_year || item.context.tag }}
                  </div>
                </div>
              </div>
              <v-clamp
                v-if="item.context && (item.context.unit_title || item.context.subtitle)"
                class="text-xs"
                autoresize
                :max-lines="1"
              >
                {{ item.context.unit_title || item.context.subtitle }}
              </v-clamp>
              <v-clamp
                v-if="meeting && meeting.chat && meeting.chat.key == item.key"
                autoresize
                :max-lines="1"
                :class="{ 'font-bold': buildUnread(item.unread) || item.key == chatActive.key }"
                class="text-xs"
                >{{ item.title }}</v-clamp
              >
              <div
                v-else-if="item.type == 'group' && !item.context?.subtitle"
                class="text-xs"
                autoresize
                :max-lines="1"
              >
                Grupo
              </div>
            </div>
            <fw-badge v-if="buildUnread(item.unread)" aria-hidden="true" class="mt-3 mr-4">{{
              buildUnread(item.unread)
            }}</fw-badge>
          </button>
        </RecycleScroller>
      </div>
      <div v-else-if="loading.chats" class="loading-more-chats absolute bottom-4 left-1/2 -ml-5">
        <div class="py-2 px-4 rounded-full bg-white shadow">
          <fw-icon-loading class="w-5 h-5 text-gray-500" />
        </div>
      </div>
      <div v-else class="flex flex-1 flex-col gap-2 items-center justify-center text-xs text-gray-400">
        {{ show_archive ? 'Sem canais arquivados' : 'Sem canais' }}
      </div>
    </div>

    <!-- Chat main window -->
    <div
      v-show="(!showNewGroup && chatActive.key && !isMobile) || (isMobile && !viewChatsList)"
      class="flex-1 h-full"
      :class="{
        minimal: chatMeetingOnly,
        'bg-teal-500 bg-opacity-10': chatActive.type == 'group',
        'bg-gray-300 bg-opacity-30': chatActive.type != 'group'
      }"
    >
      <div class="flex flex-col h-full relative">
        <!-- Chat header -->
        <div
          class="flex gap-2 px-4 py-3 items-center bg-white"
          :class="{ 'h-full': view == 'messages' && activeChatIsEmpty }"
        >
          <div
            class="w-full flex gap-2 lg:gap-3 items-center"
            :class="{ 'flex-col': view == 'messages' && activeChatIsEmpty }"
          >
            <div v-if="isMobile && !standalone && !activeChatIsEmpty" class="-mx-2">
              <fw-button>
                <fw-icon-arrow-left class="w-5 h-5" @click.native="unloadChat" />
              </fw-button>
            </div>
            <div>
              <fw-avatar
                v-if="chatActive.type == 'group'"
                :group="chatActive"
                :size="isMobile || chatMeetingOnly ? 'xs' : view == 'messages' && activeChatIsEmpty ? 'md' : 'sm'"
                animated
              />
              <fw-avatar
                v-else-if="chatActive.type == 'user'"
                :user="chatActive.user"
                :size="isMobile || chatMeetingOnly ? 'xs' : view == 'messages' && activeChatIsEmpty ? 'md' : 'sm'"
                animated
              />
              <fw-avatar
                v-else
                icon="people"
                :size="isMobile || chatMeetingOnly ? 'xs' : view == 'messages' && activeChatIsEmpty ? 'md' : 'sm'"
                animated
              />
            </div>
            <div class="flex-1" :class="{ 'text-center': view == 'messages' && activeChatIsEmpty }">
              <v-clamp class="font-bold" autoresize :max-lines="1">{{ chatActive.title }}</v-clamp>
              <div class="text-xs text-gray-500">
                {{ chatTypeDescription }}
              </div>
            </div>
            <!-- Chat main navigation -->
            <div class="flex gap-2 items-center">
              <div v-if="!isMobile || activeChatIsEmpty" class="flex gap-2 items-center">
                <fw-button
                  v-if="chatActive.type == 'group' && chatActive.meeting"
                  aria-label="Mensagens"
                  type="basic"
                  class="rounded-full w-8 h-8 md:w-10 md:h-10 items-center flex justify-center"
                  :class="{
                    'bg-white bg-opacity-70 text-primary': view == 'messages'
                  }"
                  @click.native="view = 'messages'"
                >
                  <fw-icon-chats class="w-5 h-5 md:w-6 md:h-6" />
                </fw-button>
                <fw-button
                  v-if="chatActive.type == 'group' && chatActive.meeting"
                  aria-label="Ficheiros"
                  type="basic"
                  class="rounded-full w-8 h-8 md:w-10 md:h-10 items-center flex justify-center"
                  :class="{
                    'bg-white bg-opacity-70 text-primary': view == 'buckets'
                  }"
                  @click.native="view = 'buckets'"
                >
                  <fw-icon-box-open class="w-5 h-5 md:w-6 md:h-6" />
                </fw-button>
                <span
                  v-if="chatActive.type == 'group' && chatActive.meeting && !isMobile && !activeChatIsEmpty"
                  class="border-l border-primary opacity-30 ml-1 w-1 h-5"
                ></span>
                <fw-button
                  v-if="!activeChatIsEmpty && chatActive.type != 'user'"
                  aria-label="Anúncios"
                  type="basic"
                  class="rounded-full w-8 h-8 md:w-10 md:h-10 items-center flex justify-center relative"
                  :class="{
                    'bg-white bg-opacity-70 text-primary': rightSidebar == 'anouncements'
                  }"
                  @click.native="toogleRightSidebar('anouncements')"
                >
                  <fw-icon-mega-phone class="w-5 h-5 md:w-6 md:h-6" />
                  <div
                    v-if="anouncements.length"
                    class="absolute top-0 left-6 bg-primary h-[1.1rem] min-w-[1.1rem] px-1 rounded-full flex items-center justify-center text-xs text-white"
                  >
                    {{ anouncements.length > 100 ? '99+' : anouncements.length }}
                  </div>
                </fw-button>
                <fw-button
                  v-if="!activeChatIsEmpty"
                  aria-label="Ficheiros"
                  type="basic"
                  class="rounded-full w-8 h-8 md:w-10 md:h-10 items-center flex justify-center relative"
                  :class="{
                    'bg-white bg-opacity-70 text-primary': rightSidebar == 'files'
                  }"
                  @click.native="toogleRightSidebar('files')"
                >
                  <fw-icon-file class="w-5 h-5 md:w-6 md:h-6" />
                </fw-button>
                <fw-button
                  aria-label="Informações"
                  type="basic"
                  class="rounded-full w-8 h-8 md:w-10 md:h-10 items-center flex justify-center relative"
                  :class="{
                    'bg-white bg-opacity-70 text-primary': rightSidebar == 'info'
                  }"
                  @click.native="toogleRightSidebar('info')"
                >
                  <fw-icon-info class="w-5 h-5 md:w-6 md:h-6" />
                </fw-button>
              </div>
              <span
                v-if="!isMobile && !activeChatIsEmpty && false"
                class="border-l border-primary opacity-30 ml-1 w-1 h-5"
              ></span>
              <fw-button
                v-if="!activeChatIsEmpty && false"
                aria-label="Tradução automática"
                class="rounded-full items-center flex justify-center px-2 relative"
                :class="{
                  'text-primary': aiTranslateOn
                }"
                @click.native="showAiTranslateModal"
              >
                <fw-icon-translate class="w-5 h-5 md:w-6 md:h-6 mr-1" />
                <fw-icon-robot class="w-4 h-4 md:w-5 md:h-5" />
                <fw-dot-active v-if="aiTranslateOn" class="h-2 w-2 absolute top-1 right-1" />
              </fw-button>
              <b-dropdown v-if="isMobile && !activeChatIsEmpty" aria-role="list" position="is-bottom-left">
                <template #trigger="{ active }">
                  <fw-button size="sm">
                    <fw-icon-more class="w-6 h-6" :class="{ 'opacity-50': active }" />
                  </fw-button>
                </template>
                <b-dropdown-item aria-role="listitem" class="items-center flex gap-2" @click.native="view = 'messages'">
                  <span class="w-6"><fw-icon-chats class="w-5 h-5"/></span> Mensagens
                </b-dropdown-item>
                <!-- <b-dropdown-item
                  v-if="chatActive.type == 'group'"
                  aria-role="listitem"
                  class="items-center flex gap-2"
                  @click.native="view = 'buckets'"
                >
                  <span class="w-6"><fw-icon-box-open class="w-5 h-5"/></span> Ficheiros
                </b-dropdown-item> -->
                <b-dropdown-item aria-role="listitem" class="items-center flex gap-2" @click.native="view = 'files'">
                  <span class="w-6"><fw-icon-box-open class="w-5 h-5"/></span> Ficheiros
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  class="items-center flex gap-2"
                  @click.native="view = 'anouncements'"
                >
                  <span class="w-6"><fw-icon-mega-phone class="w-5 h-5"/></span> Anúncios
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="chatActive.meeting?.key_alias"
                  aria-role="listitem"
                  class="items-center flex gap-2"
                  @click="$router.push({ name: 'live', params: { key: chatActive.meeting.key_alias } })"
                >
                  <span class="w-6"><fw-icon-live class="w-5 h-5"/></span> Sala virtual
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="panelForGroups && chatActive.type == 'group' && meetingPermissions.permissions"
                  aria-role="listitem"
                  class="items-center flex gap-2"
                  @click="view = 'users'"
                >
                  <span class="w-6"><fw-icon-people class="w-5 h-5"/></span> Utilizadores
                </b-dropdown-item>
              </b-dropdown>
              <fw-button
                v-if="isMobile && !activeChatIsEmpty"
                aria-label="Informações"
                type="basic"
                class="rounded-full w-8 h-8 md:w-10 md:h-10 items-center flex justify-center relative"
                :class="{
                  'bg-white bg-opacity-70 text-primary': view == 'info'
                }"
                @click.native="toogleView('info')"
              >
                <fw-icon-info class="w-5 h-5 md:w-6 md:h-6" />
              </fw-button>
            </div>
            <div v-if="view == 'messages' && activeChatIsEmpty" class="mt-5 text-xs text-gray-500">
              Canal sem mensagens.
            </div>
            <fw-button
              v-if="view == 'messages' && isMobile && activeChatIsEmpty"
              class="mt-10"
              size="xs"
              type="link-muted"
              @click.native="unloadChat"
            >
              Voltar à página anterior
            </fw-button>
          </div>
        </div>
        <div
          class="p-0 bg-white overflow-hidden relative transition-all duration-1000"
          :class="{
            'h-0': view != 'messages' && !activeChatIsEmpty && anouncements.length == 0,
            'h-[3.85rem]': view == 'messages' && !activeChatIsEmpty && anouncements.length > 0
          }"
          @mouseenter="pauseAnouncements = true"
          @mouseleave="pauseAnouncements = false"
        >
          <div
            v-for="(anouncement, a) in anouncements"
            :key="'anounc_' + anouncement.key"
            class="h-[3.25rem] overflow-hidden pt-1.5 px-2.5 flex text-sm left-0 right-0 font-medium bg-gray-100 rounded-md border border-gray-50 mx-4 transition-all absolute duration-1000 select-none cursor-default"
            :class="{
              '-top-16': a < currentAnouncement,
              'top-0': a == currentAnouncement,
              'top-16': a > currentAnouncement
            }"
            :style="{
              'z-index': a == currentAnouncement ? 1 : 0
            }"
            @click="openAnouncement(anouncement.key)"
          >
            <UserAvatar :user="getChatUser(anouncement.user_key)" size="xs" class="flex-shrink-0 mt-0.5" />
            <div class="relative h-full pl-2 w-full">
              <div
                class="flex gap-1 text-gray-500 py-0.5 px-1.5 items-center text-xs rounded-full absolute top-0 -right-1"
              >
                <fw-icon-mega-phone class="w-4 h-4" />
                <span>Anúncio</span>
              </div>
              <div class="flex items-center gap-2 h-3 overflow-hidden mt-[0.25rem]">
                <v-clamp autoresize :max-lines="1" class="font-bold text-sm">
                  {{ getChatUser(anouncement.user_key).name }}
                </v-clamp>
                <div class="text-xs opacity-60 flex-1">{{ anouncement.created_date | formatDateTime }}</div>
              </div>
              <div v-html="anouncement.message"></div>
              <div class="absolute bottom-0 left-0 right-0 h-5 bg-gradient-to-t from-gray-100 to-transparent"></div>
            </div>
          </div>
        </div>
        <!-- Messages panel -->
        <PanelChatUsers
          v-if="view == 'users'"
          :key="chatActiveKey"
          :chat-active="chatActive"
          @right-sidebar="view = $event"
        />
        <PanelBucketAdd
          v-if="view == 'new-bucket'"
          :application-context="{ application: 'meetings', item_type: 'groups', item_key: chatActive.meeting.key }"
          class="mx-auto max-w-prose"
          @go-back="view = 'buckets'"
        />
        <div v-if="view == 'anouncements'" class="flex flex-col h-full bg-gray-100">
          <fw-heading class="mx-4 py-3.5">Anúncios</fw-heading>
          <div id="chat-anouncements" class="flex-1 overflow-y-auto flex flex-col">
            <ChatMessage
              v-for="anouncement in anouncements"
              :key="'a_' + anouncement.key"
              :message="anouncement"
              :chat-type="chatActive.type"
              :chat-key="chatActive.key"
              :user="getChatUser(anouncement.user_key)"
              :allow-downloads="allowDownloads"
              :can-delete-all="chatActive.isManager"
              :ai-translate-on="aiTranslateOn"
              :can-promote-all="chatActive.isManager"
              @delete-message="deleteChatMessage"
              @promote-message="promoteMessage(anouncement, $event)"
              @unpromote-message="unpromoteMessage(anouncement)"
              @show-options="showChatOptions"
              @reply-message="previewReply"
              @click-reply="scrollToMessage($event)"
              @preview-file="previewFile(anouncement, $event)"
            />
            <div v-if="anouncements.length == 0" class="h-full flex flex-col items-center justify-center relative">
              <fw-icon-mega-phone class="w-10 h-10 text-gray-300" />
              <fw-label class="mt-2 opacity-60 pb-16">Sem anúncios</fw-label>
              <div class="absolute bottom-5 left-0 right-0">
                <div class="flex px-5 rounded-md mt-10 flex-col gap-2 text-sm">
                  <b>O que são?</b>
                  <fw-icon-info v-if="false" class="h-5 w-5 flex-shrink-0" />
                  <div>Os anúncios são mensagens importantes que podem ser destacadas para todos os participantes.</div>
                </div>
                <div v-if="chatActive.isManager" class="flex flex-col gap-2 px-5 rounded-md mt-5 text-sm">
                  <b>Como criar um anúncio?</b>
                  <div>
                    Para criar um anúncio, clique no botão <fw-icon-more class="w-5 h-5 inline-block mx-0.5" /> junto à
                    mensagem e clique em <b>Promover</b>.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PanelChatFiles v-else-if="view == 'files'" :files="files" @preview-file="previewFile" />
        <PanelChatInfo
          v-else-if="view == 'info'"
          :key="chatActiveKey"
          :chat-active="chatActive"
          :meeting-permissions="meetingPermissions"
          :archive-allowed="archiveAllowed"
          :archiving="archiving"
          :silencing="silencing"
          :ai-translate-on="aiTranslateOn"
          :ai-translate-language="aiTranslateLanguage"
          :is-mobile="isMobile"
          :change-group-title="changeGroupTitle"
          :silence-chat="silenceChat"
          :show-ai-translate-modal="showAiTranslateModal"
          :delete-group="deleteGroup"
          :archive-chat="archiveChat"
          :unarchive-chat="unarchiveChat"
          :chat-meeting-only="chatMeetingOnly"
          @right-sidebar="view = $event"
        />
        <fw-panel v-if="view == 'buckets'" title="Ficheiros" featured class="p-5">
          <template v-if="chatActive.meeting && chatActive.meeting.permissions.bucket_create" #toolbar>
            <fw-button type="regular" @click.native="view = 'new-bucket'">Novo</fw-button>
          </template>
          <PanelBucketList
            :create-allowed="chatActive.meeting && chatActive.meeting.permissions.bucket_create"
            :application-context="{ application: 'meetings', item_type: 'groups', item_key: chatActive.meeting.key }"
            @go-to-new-bucket="view = 'new-bucket'"
          />
        </fw-panel>
        <div
          v-else-if="view == 'messages' && !activeChatIsEmpty && !chatLocked"
          id="chat-messages"
          class="flex-1 overflow-y-auto h-full relative"
          @dragover="dragover"
        >
          <!-- No messages yet... -->
          <div
            class="chat-channel-messages relative"
            :class="{
              'h-full': activeChatIsEmpty,
              'h-auto': !activeChatIsEmpty
            }"
          >
            <div class="chat-options absolute bg-white rounded-md py-3" style="display: none">Chat options</div>
            <!-- Lets go... -->
            <DynamicScroller
              v-if="!activeChatIsEmpty && !chatLocked"
              :items="chatActive.messages"
              :min-item-size="30"
              :buffer="50"
              key-field="key"
              class="scroller"
              @visible="chatMessagesReady"
              @scroll-start="hideChatOptions"
            >
              <template v-slot="{ item, index, active }">
                <DynamicScrollerItem :item="item" :active="active" :data-index="index">
                  <ChatMessage
                    :key="item.key"
                    :message="item"
                    :chat-type="chatActive.type"
                    :chat-key="chatActive.key"
                    :user="getChatUser(item.user_key)"
                    :parent-user="item.parent_message ? getChatUser(item.parent_message.user_key) : null"
                    :allow-downloads="allowDownloads"
                    :can-delete-all="chatActive.isManager"
                    :can-promote-all="chatActive.isManager"
                    :ai-translate-on="aiTranslateOn"
                    :previous-message="index > 0 ? chatActive.messages[index - 1] : null"
                    :next-message="index < chatActive.messages.length - 1 ? chatActive.messages[index + 1] : null"
                    :is-last="index == chatActive.messages.length - 1"
                    featured
                    @delete-message="deleteChatMessage"
                    @show-options="showChatOptions"
                    @reply-message="previewReply"
                    @click-reply="scrollToMessage($event)"
                    @promote-message="promoteMessage(item, $event)"
                    @unpromote-message="unpromoteMessage(item)"
                    @preview-file="previewFile(item, $event)"
                  />
                </DynamicScrollerItem>
              </template>
            </DynamicScroller>
          </div>
          <div class="loading-more-messages absolute top-4 left-1/2 -ml-5 hidden">
            <div class="py-2.5 px-5 rounded-full bg-white shadow">
              <fw-icon-loading class="w-6 h-6 text-gray-500" />
            </div>
          </div>
        </div>
        <div
          v-else-if="chatLocked && !activeChatIsEmpty"
          class="flex-1 h-full relative flex items-center justify-center"
        >
          <div class="py-2.5 px-5 rounded-full bg-white shadow">
            <fw-icon-loading class="w-6 h-6 text-gray-500" />
          </div>
        </div>
        <!-- End messages panel -->
        <div
          v-if="view == 'messages'"
          class="flex items-end bg-white shadow-md border-b border-gray-200"
          :class="{
            'pr-0.5 pl-0.5 py-3': minimal,
            'md:gap-2 px-2 py-3 md:p-4': !minimal
          }"
        >
          <div v-if="!showPermissions && chatActive.unread && !sendingMessage">
            <!-- New messages -->
            <button
              class="focus:outline-none rounded-full p-2 flex items-center bg-primary text-white"
              :alt="chatActive.unread > 1 ? 'mensagens novas' : 'mensagem nova'"
              @click="scrollToBottom"
            >
              <fw-icon-mail-unread class="w-5 h-5"></fw-icon-mail-unread>
              <span class="font-bold mx-1">{{ buildUnread(chatActive.unread) }}</span>
              <span class="animate-bounce">&darr;</span>
            </button>
          </div>
          <div v-if="editable && showUploader" class="flex items-center gap-0">
            <Uploader
              :is-docked="true"
              :layout="showFullDragZoneUploader ? 'full-dragzone' : 'tiny'"
              reference-id="upload"
              allowed="any"
              :clear-after="true"
              bucket-code="files"
              bucket-type="file"
              input-id="upload"
              @upload="chatUploadFileToMessage"
              @dragleave="dragleave"
            />
            <b-tooltip v-if="chatActive.files.length" type="is-light" label="Eliminar anexos">
              <div
                class="bg-black/90 text-white group w-[3.75rem] transition-all cursor-pointer overflow-hidden flex flex-nowrap items-center font-bold px-2 py-1 rounded-full mr-2"
                @click="deleteAttachments"
              >
                <fw-icon-attachment class="w-5 h-5 flex-shrink-0" />
                <div class="w-5 text-center flex-shrink-0 mr-0.5">
                  {{ chatActive.files.length >= 100 ? 99 : chatActive.files.length }}
                </div>
              </div>
            </b-tooltip>
          </div>
          <div class="flex-1">
            <div v-if="editable" class="rounded-md" style="background-color: #eee">
              <div v-if="replyTo" class="pt-1 px-1 select-none cursor-default">
                <ChatMessageReply
                  :message="replyTo"
                  :user="getChatUser(replyTo.user_key)"
                  show-close
                  @close="replyTo = null"
                />
              </div>
              <ChatEditor ref="chateditor" v-model="chatActive.message" @enter="sendMessage()" />
            </div>
            <div v-else class="flex gap-2 text-gray-400 text-xs items-center px-4 py-2">
              <fw-icon-lock class="h-4 w-4" />
              O envio de mensagens está desativado
            </div>
          </div>
          <div v-if="editable" class="ml-2 mr-2 mb-1 flex items-center">
            <b-dropdown
              v-if="promoteSentMessage"
              ref="message_options"
              aria-role="list"
              close-on-click
              position="is-top-left"
            >
              <template #trigger>
                <button
                  class="focus:outline-none flex items-center py-1 px-2 mr-1 rounded-full transition-all bg-gray-200 text-gray-700 text-sm font-semibold gap-0.5 leading-6"
                >
                  <fw-icon-timer class="h-5 w-5" />
                  <fw-icon-infinity v-if="announcementDuration == 'forever'" class="h-5 w-5 mx-2.5 my-0.5" />
                  <span v-else class="w-10 text-center block h-6 whitespace-nowrap">
                    {{
                      announcementDuration == 'day'
                        ? '1 dia'
                        : announcementDuration == 'week'
                        ? '1 sem'
                        : announcementDuration == 'month'
                        ? '1 mês'
                        : '∞'
                    }}
                  </span>
                </button>
              </template>
              <div class="py-1">
                <b-dropdown-item custom paddingless>
                  <fw-label size="xs" class="ml-4">Duração</fw-label>
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  class="w-full hover:bg-gray-100 flex gap-2.5 cursor-pointer font-medium text-sm"
                  @click.native="announcementDuration = 'day'"
                >
                  1 dia
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  class="w-full hover:bg-gray-100 flex gap-2.5 cursor-pointer font-medium text-sm"
                  @click.native="announcementDuration = 'week'"
                >
                  1 semana
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  class="w-full hover:bg-gray-100 flex gap-2.5 cursor-pointer font-medium text-sm"
                  @click.native="announcementDuration = 'month'"
                >
                  1 mês
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem"
                  class="w-full hover:bg-gray-100 flex gap-2.5 cursor-pointer font-medium text-sm"
                  @click.native="announcementDuration = 'forever'"
                >
                  Para sempre
                </b-dropdown-item>
              </div>
            </b-dropdown>
            <b-dropdown
              v-if="chatActive.isManager"
              ref="message_options"
              aria-role="list"
              close-on-click
              position="is-top-left"
              append-to-body
            >
              <template #trigger>
                <button
                  :disabled="sendIsDisabled"
                  class="focus:outline-none flex items-center py-2 rounded-l-full transition-all pr-0.5 pl-1.5 border-r border-black/5"
                  :class="{
                    'bg-primary text-white': !sendIsDisabled,
                    'text-white bg-gray-300 cursor-not-allowed': sendIsDisabled
                  }"
                >
                  <fw-icon-more class="h-5 w-5 rotate-90" />
                </button>
              </template>
              <b-dropdown-item
                v-if="chatActive.isManager && !promoteSentMessage"
                aria-role="listitem"
                class="w-full hover:bg-gray-100 flex gap-3 cursor-pointer font-medium text-sm"
                @click.native.stop="promoteSentMessage = true"
              >
                <fw-icon-mega-phone class="h-5 w-5 text-gray-400" /> {{ $t('send_as_announcement') }}
              </b-dropdown-item>
              <b-dropdown-item
                v-if="chatActive.isManager && promoteSentMessage"
                aria-role="listitem"
                class="w-full hover:bg-gray-100 flex gap-3 cursor-pointer font-medium text-sm"
                @click.native.stop="promoteSentMessage = false"
              >
                <fw-icon-chats class="h-5 w-5 text-gray-400" /> {{ $t('send_as_message') }}
              </b-dropdown-item>
            </b-dropdown>
            <button
              :loading="sendingMessage"
              :disabled="sendIsDisabled"
              class="focus:outline-none flex items-center py-2 transition-all"
              :class="{
                'bg-primary text-white': !sendIsDisabled,
                'text-white bg-gray-300 cursor-not-allowed': sendIsDisabled,
                'rounded-r-full pr-[1.125rem] pl-2': chatActive.isManager,
                'rounded-full pl-4 pr-[1.125rem]': !chatActive.isManager
              }"
              @click="sendMessage()"
            >
              <span class="relative w-5 h-5">
                <fw-icon-mega-phone
                  class="h-5 w-5 absolute transition-all transform"
                  :class="{
                    'opacity-100': promoteSentMessage,
                    'opacity-0': !promoteSentMessage
                  }"
                />
                <svg
                  class="fill-current w-[1.3rem] h-[1.3rem] absolute transition-all transform"
                  :class="{
                    'rotate-45': !sendIsDisabled,
                    'opacity-0': promoteSentMessage
                  }"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Right side bar -->
    <div
      class="md:h-full flex flex-col border-l overflow-x-hidden bg-gray-100"
      :class="{
        'absolute z-[3] bottom-0 top-16 left-0 right-0 sm:left-auto md:static sm:w-[345px] border-gray-200':
          rightSidebar == 'info' ||
          rightSidebar == 'files' ||
          rightSidebar == 'anouncements' ||
          rightSidebar == 'users',
        'hidden border-transparent': rightSidebar == null
      }"
    >
      <div class="h-full w-full relative">
        <div
          v-if="rightSidebar != 'users'"
          class="h-7 w-7 bg-gray-200 cursor-pointer select-none hover:bg-gray-100 text-gray-600 rounded-full flex items-center absolute top-4 right-4 justify-center"
          @click="rightSidebar = null"
        >
          <fw-icon-close class="w-5 h-5" />
        </div>
        <div v-if="rightSidebar == 'anouncements'" class="flex flex-col h-full">
          <fw-heading class="mx-4 py-3.5">Anúncios</fw-heading>
          <div id="chat-anouncements" class="flex-1 overflow-y-auto flex flex-col">
            <ChatMessage
              v-for="anouncement in anouncements"
              :key="'a_' + anouncement.key"
              :message="anouncement"
              :chat-type="chatActive.type"
              :chat-key="chatActive.key"
              :user="getChatUser(anouncement.user_key)"
              :allow-downloads="allowDownloads"
              :can-delete-all="chatActive.isManager"
              :ai-translate-on="aiTranslateOn"
              :can-promote-all="chatActive.isManager"
              @delete-message="deleteChatMessage"
              @promote-message="promoteMessage(anouncement, $event)"
              @unpromote-message="unpromoteMessage(anouncement)"
              @show-options="showChatOptions"
              @reply-message="previewReply"
              @click-reply="scrollToMessage($event)"
              @preview-file="previewFile(anouncement, $event)"
            />
            <div v-if="anouncements.length == 0" class="h-full flex flex-col items-center justify-center relative">
              <fw-icon-mega-phone class="w-10 h-10 text-gray-300" />
              <fw-label class="mt-2 opacity-60 pb-16">Sem anúncios</fw-label>
              <div class="absolute bottom-5 left-0 right-0">
                <div class="flex px-5 rounded-md mt-10 flex-col gap-2 text-sm">
                  <b>O que são?</b>
                  <fw-icon-info v-if="false" class="h-5 w-5 flex-shrink-0" />
                  <div>Os anúncios são mensagens importantes que podem ser destacadas para todos os participantes.</div>
                </div>
                <div v-if="chatActive.isManager" class="flex flex-col gap-2 px-5 rounded-md mt-5 text-sm">
                  <b>Como criar um anúncio?</b>
                  <div>
                    Para criar um anúncio, clique no botão <fw-icon-more class="w-5 h-5 inline-block mx-0.5" /> junto à
                    mensagem e clique em <b>Promover</b>.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PanelChatFiles v-else-if="rightSidebar == 'files'" :files="files" @preview-file="previewFile" />
        <PanelChatInfo
          v-else-if="rightSidebar == 'info'"
          :key="chatActiveKey"
          :chat-active="chatActive"
          :meeting-permissions="meetingPermissions"
          :archive-allowed="archiveAllowed"
          :archiving="archiving"
          :silencing="silencing"
          :ai-translate-on="aiTranslateOn"
          :ai-translate-language="aiTranslateLanguage"
          :is-mobile="isMobile"
          :change-group-title="changeGroupTitle"
          :silence-chat="silenceChat"
          :show-ai-translate-modal="showAiTranslateModal"
          :delete-group="deleteGroup"
          :archive-chat="archiveChat"
          :unarchive-chat="unarchiveChat"
          :chat-meeting-only="chatMeetingOnly"
          @right-sidebar="rightSidebar = $event"
        />
        <PanelChatUsers
          v-else-if="rightSidebar == 'users'"
          :key="chatActiveKey"
          :chat-active="chatActive"
          @right-sidebar="rightSidebar = $event"
        />
        <div v-else-if="rightSidebar == 'users2'" class="flex flex-col h-full">
          <div class="flex px-2 items-center py-3.5 gap-1">
            <fw-button type="transparent" @click.native="rightSidebar = 'info'">
              <fw-icon-chevron-left class="w-5 h-5" />
            </fw-button>
            <fw-heading class="flex-1">Participantes</fw-heading>
            <fw-button type="light-primary" icon="add">
              Adicionar
            </fw-button>
          </div>
          <div id="users-list" class="flex-1 overflow-y-auto flex flex-col px-2">
            <div class="bg-white p-1.5 rounded-lg flex flex-col">
              <fw-person v-for="user in users" :key="user.key" paddingless :person="user" :size="'xs'" class="p-1">
                <template #options>
                  <fw-menu-more size="xs">
                    <b-dropdown-item paddingless aria-role="listitem">
                      <fw-button
                        type="dropdown-item"
                        class="w-full"
                        label="Alterar permissões"
                        @click.native="deleteClass()"
                      >
                        Alterar permissões
                      </fw-button>
                    </b-dropdown-item>
                    <b-dropdown-item paddingless aria-role="listitem">
                      <fw-button type="dropdown-item" class="w-full" label="Remover" @click.native="newClass()">
                        Remover
                      </fw-button>
                    </b-dropdown-item>
                  </fw-menu-more>
                </template>
              </fw-person>
            </div>
            <div v-if="users.length == 0" class="h-full flex flex-col items-center justify-center relative">
              <fw-icon-user class="w-10 h-10 text-gray-300" />
              <fw-label class="mt-2 opacity-60 pb-16">Sem participantes</fw-label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modals -->
    <ModalMessagesFilePreview
      v-if="preview"
      :message="preview.message"
      :file-key="preview.fileKey"
      :user="preview.user"
      @close="preview = null"
      @reply-message="previewReply"
      @go-to-message="scrollToMessage"
    />
    <b-modal
      v-if="isAiTranslateModalActive"
      :can-cancel="true"
      trap-focus
      :active.sync="isAiTranslateModalActive"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="360"
      scroll="keep"
    >
      <div class="px-5 pt-3 pb-5">
        <div class="flex items-center justify-between">
          <fw-label>Tradução automática de mensagens</fw-label>
          <fw-button type="link" size="sm" @click.native="isAiTranslateModalActive = false">Fechar</fw-button>
        </div>
        <div class="p-5 flex flex-col items-center justify-center mx-auto">
          <div class="mb-7 font-medium text-center">
            Traduza automaticamente as mensagens dos participantes no idioma que precisar.
          </div>
          <div
            class="flex items-center gap-1 relative"
            :class="{ 'text-primary': aiTranslateOn, 'text-gray-500': !aiTranslateOn }"
          >
            <fw-icon-translate class="w-10 h-10" />
            <fw-icon-robot class="w-10 h-10" />
            <fw-dot-active v-if="aiTranslateOn" class="h-3 w-3 absolute -top-1 -right-1" />
          </div>
          <b-switch
            v-model="aiTranslateOn"
            class="-mr-2 p-2"
            @input="updateOption('chat_show_translated', aiTranslateOn)"
          ></b-switch>
          <div class="text-xs font-medium" :class="{ 'text-primary': aiTranslateOn, 'text-gray-300': !aiTranslateOn }">
            {{ !aiTranslateOn ? 'Desativado' : 'Ativado' }}
          </div>
          <fw-label class="mt-4">Traduzir para</fw-label>
          <b-select
            v-model="aiTranslateLanguage"
            placeholder="Indique um idioma"
            @input="updateOption('language', aiTranslateLanguage)"
          >
            <option v-for="lang in aiTranslateLanguages" :key="lang.key" :value="lang.key">
              {{ lang.title }}
            </option>
          </b-select>
        </div>
        <div class="mt-4 pt-4 border-t border-gray-300 text-sm text-gray-500">
          Com esta funcionalidade pode traduzir individualmente cada mensagem, através do botão "Traduzir". O texto
          original está também sempre disponível.
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ChatEditor from '../ChatEditor'
import { RecycleScroller } from 'vue-virtual-scroller'
import { DynamicScroller } from 'vue-virtual-scroller'
import { DynamicScrollerItem } from 'vue-virtual-scroller'
import ServiceChat from '@/fw-modules/fw-core-vue/chats/services/ServiceChat'
import ServiceMeetings from '@/fw-modules/fw-meetings-vue/services/ServiceMeetings'
import ChatUtils from '@/fw-modules/fw-core-vue/chats/services/utilities'
import ChatMessage from '@/fw-modules/fw-core-vue/chats/components/ChatMessage'
import ChatMessageReply from '@/fw-modules/fw-core-vue/chats/components/ChatMessageReply'
//import PanelPermissions from '@/fw-modules/fw-core-vue/chats/components/panels/PanelPermissions'
import Uploader from '@/fw-modules/fw-core-vue/storage/components/Uploader'
import ServiceAuth from '@/fw-modules/fw-core-vue/id/services/ServiceAuth'
import PanelBucketAdd from '@/fw-modules/fw-core-vue/buckets/components/panels/PanelBucketAdd'
import PanelBucketList from '@/fw-modules/fw-core-vue/buckets/components/panels/PanelBucketList'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import UserAvatar from '@/fw-modules/fw-core-vue/id/components/user/UserAvatar'
//import ChatFiles from '@/fw-modules/fw-core-vue/chats/components/ChatFiles'
import ModalMessagesFilePreview from '@/fw-modules/fw-core-vue/chats/components/modals/ModalMessagesFilePreview'
import PanelChatInfo from '@/fw-modules/fw-core-vue/chats/components/panels/PanelChatInfo'
import PanelChatFiles from '@/fw-modules/fw-core-vue/chats/components/panels/PanelChatFiles'
import PanelChatUsers from '@/fw-modules/fw-core-vue/chats/components/panels/PanelChatUsers'
export default {
  components: {
    PanelBucketAdd,
    PanelBucketList,
    ChatMessage,
    //PanelPermissions,
    Uploader,
    RecycleScroller,
    DynamicScroller,
    DynamicScrollerItem,
    ChatEditor,
    UserAvatar,
    ChatMessageReply,
    //ChatFiles,
    ModalMessagesFilePreview,
    PanelChatInfo,
    PanelChatFiles,
    PanelChatUsers
  },

  props: {
    editable: {
      type: Boolean,
      default: true
    },
    mobileView: {
      type: Boolean,
      default: false
    },
    standalone: {
      type: Boolean,
      default: false
    },
    allowDownloads: {
      type: Boolean,
      default: true
    },
    chatFilters: {
      type: Array,
      deep: true,
      default: null
    },
    chats: {
      type: Array,
      deep: true
    },
    chatActive: Object,
    meeting: Object,
    chatPagination: Object,
    chatArchived: Object,
    chatMeetingOnly: {
      type: Boolean,
      default: false
    },
    panelForGroups: {
      type: Boolean,
      default: false
    },
    addAllowed: {
      type: Boolean,
      default: false
    },
    chatUsers: Array,
    allChatUsers: Object,
    emptyTitle: {
      type: String,
      default: 'Não existem canais de conversação para apresentar.'
    },
    loadChatOnCreate: {
      type: Boolean,
      default: true
    },
    viewChatsList: {
      type: Boolean,
      default: true
    },
    minimal: {
      type: Boolean,
      default: false
    },
    showUploader: {
      type: Boolean,
      default: true
    },
    chatLocked: {
      type: Boolean,
      default: false
    },
    loadOldMessagesCore: {
      type: Function,
      default: null
    },
    loadMoreChatsCore: {
      type: Function,
      default: null
    },
    archiveAllowed: {
      type: Boolean,
      default: true
    }
  },

  data() {
    const user = this.$store.getters.getUser
    return {
      mobileShowChatsList: true,
      mobileShowActiveChat: false,
      activeFilter: null,
      show_archive: false,
      sendingMessage: false,
      newGroupTitle: '',
      showNewGroup: false,
      showPermissions: false,
      search: '',
      email: null,
      showFullDragZoneUploader: false,
      view: 'messages',
      aiTranslateOn: user && user.options ? user.options.chat_show_translated : false,
      aiTranslateLanguages: [],
      aiTranslateLanguage: user && user.options ? user.options.language : 'pt',
      isAiTranslateModalActive: false,
      chatSearch: '',
      chatSearchOpen: false,
      rightSidebar: null,
      anouncements: [],
      files: [],
      currentAnouncement: 0,
      anouncementsTimer: null,
      pauseAnouncements: false,
      replyTo: null,
      loading: {
        files: false,
        anouncements: false,
        chat: false,
        email: false,
        group: false,
        chats: false,
        archive: false
      },
      paginationFiles: {
        current_page: 1
      },
      preview: null,
      silencing: false,
      archiving: false,
      promoteSentMessage: false,
      announcementDuration: 'week',
      chatsArchived: []
    }
  },

  computed: {
    users() {
      return this.chatActive ? this.chatActive.users : []
    },
    language() {
      return this.$store.state.language
    },
    chatActiveKey() {
      return this.chatActive ? this.chatActive.key : null
    },
    isMobile() {
      return window.innerWidth < 640 || this.mobileView
    },
    chatTabDescription() {
      const tabMap = {
        messages: 'Mensagens',
        users: 'Permissões',
        buckets: 'Ficheiros',
        settings: 'Configurações'
      }
      return tabMap[this.view]
    },
    chatTypeDescription() {
      const typeMap = {
        group: 'Grupo',
        user: 'Utilizador/a',
        class: 'Turma'
      }
      return !this.chatMeetingOnly ? typeMap[this.chatActive.type] : 'Canal da sala'
    },
    activeChats() {
      if (this.show_archive) {
        return this.chatsArchived
      } else {
        const chats = []
        if (!this.activeFilter && !this.chatSearch) {
          for (let chat of this.chats) {
            if (!chat.is_archived) {
              chats.push(chat)
            }
          }
          for (let user of this.chatUsers) {
            chats.push(user.chat)
          }
        } else {
          const search = this.chatSearch ? utils.existsInSearchFactory(this.chatSearch) : null
          for (let chat of this.chats) {
            if (
              !chat.is_archived &&
              (!this.activeFilter || chat.type === this.activeFilter) &&
              (!search ||
                search(chat.title) ||
                (chat.type == 'class' &&
                  chat.context &&
                  (search(chat.context.academic_year) || search(chat.context.unit_title))))
            ) {
              chats.push(chat)
            }
          }

          if (!this.activeFilter || this.activeFilter === 'user') {
            for (let user of this.chatUsers) {
              if ((!search || search(user.name)) && !user.is_archived) {
                chats.push(user.chat)
              }
            }
          }
        }

        return chats
      }
    },
    isEmpty() {
      return !this.chats.length && !this.chatUsers.length
    },
    activeChatIsEmpty() {
      return !this.chatActive.messages.length
    },
    authUser() {
      return this.$store.getters.getUser
    },
    sendIsDisabled() {
      return (
        this.chatActive.files.length == 0 && (this.chatActive.message == null || this.chatActive.message.length == 0)
      )
    },
    meetingPermissions() {
      const meeting = this.chatActive.meeting
      if (meeting && meeting.permissions) return meeting.permissions
      else return {}
    },
    emailIsReady() {
      if (!this.email) return false

      const parts = this.email
        .toLocaleLowerCase()
        .trim()
        .split('@')
      return parts.length == 2
    }
  },

  watch: {
    chatActiveKey: {
      handler(value) {
        console.log('chatActive', value)
        if (value) {
          this.replyTo = null
          this.promoteSentMessage = false
          this.announcementDuration = 'week'
          this.users = []
          this.files = []
          this.loadAnouncements()
          this.loadUsers()
          if (this.rightSidebar == 'files') {
            this.loadChatFiles(true)
          }
        } else {
          this.users = []
          this.files = []
        }
      }
      //deep: true
    },
    viewChatsList(value) {
      if (value) this.unloadChat()
    },
    view() {
      console.log('view changed', this.view)
      this.loadData(this.view)
    }
  },

  beforeDestroy() {
    if (this.chatActive.key) {
      this.$emit('unload-chat', this.chatActive.key)
    }
  },

  created() {
    if (this.loadChatOnCreate && !this.isMobile) {
      this.$emit('load-main-chat', this.chatLoadDone)
    }
    //this.loadAnouncements()
  },

  methods: {
    toogleView(view) {
      this.view = this.view == view ? 'messages' : view
    },
    loadData(view) {
      if (view == 'anouncements') {
        this.loadAnouncements()
      } else if (view == 'files') {
        this.files = []
        this.loadChatFiles()
      }
    },
    openChannelSearch() {
      this.chatSearchOpen = true
      this.$nextTick(() => {
        this.$refs.chatSearchInput.focus()
      })
    },
    closeChannelSearch() {
      this.chatSearchOpen = false
      this.chatSearch = ''
    },
    showArchivedChats() {
      if (this.show_archive) {
        this.show_archive = false
      } else {
        this.loading.archive = true
        ServiceChat.getChannels(null, null, null, true)
          .then(response => {
            console.log('showArchivedChats', response)
            this.chatsArchived = response.channels
            this.show_archive = true
          })
          .finally(() => {
            this.loading.archive = false
          })
      }
    },
    async loadMoreChats() {
      console.log('scroll ended')
      if (this.loadMoreChatsCore && !this.loading.chats) {
        console.log('loadMoreChats')
        this.loading.chats = true
        try {
          await this.loadMoreChatsCore()
        } catch (error) {
          console.error('loadMoreChats', error)
        } finally {
          setTimeout(() => {
            this.loading.chats = false
          }, 200)
        }
      }
    },
    archiveChat(key) {
      this.$buefy.dialog.confirm({
        title: 'Arquivar canal',
        message: 'Tem a certeza que deseja arquivar este canal?',
        confirmText: 'Arquivar',
        cancelText: 'Cancelar',
        type: 'is-danger',
        onConfirm: () => {
          this.archiving = true
          ServiceChat.archiveChat(key)
            .then(response => {
              console.log('archiveChat', response)
              this.chatActive.is_archived = true
              this.$emit('update-active-chat', {
                is_archived: true
              })
            })
            .finally(() => {
              this.archiving = false
            })
        }
      })
    },
    unarchiveChat(key) {
      this.archiving = true
      ServiceChat.unarchiveChat(key)
        .then(response => {
          console.log('unarchiveChat', response)
          this.chatActive.is_archived = false
          this.$emit('update-active-chat', {
            is_archived: false
          })
        })
        .finally(() => {
          this.archiving = false
        })
    },
    changeGroupTitle() {
      if (this.meetingPermissions.edit) {
        this.$buefy.dialog.prompt({
          title: 'Mudar nome do grupo',
          message: 'Indique o novo nome do grupo',
          confirmText: 'Guardar',
          cancelText: 'Cancelar',
          inputAttrs: {
            placeholder: 'Novo título',
            value: this.chatActive.title
          },
          onConfirm: value => {
            this.chatActive.title = value
            this.saveGroup()
          }
        })
      }
    },
    silenceChat() {
      if (this.silencing) return
      this.silencing = true
      if (!this.chatActive.is_silent) {
        ServiceChat.silentChat(this.chatActive.key)
          .then(response => {
            console.log('silenceChat', response)
            this.chatActive.is_silent = true
            this.$emit('update-active-chat', {
              is_silent: true
            })
          })
          .finally(() => {
            this.silencing = false
          })
      } else {
        ServiceChat.unsilentChat(this.chatActive.key)
          .then(response => {
            console.log('unsilenceChat', response)
            this.chatActive.is_silent = false
            this.$emit('update-active-chat', {
              is_silent: false
            })
          })
          .finally(() => {
            this.silencing = false
          })
      }
    },
    deleteAttachments() {
      this.chatActive.files = []
    },
    previewReply(message) {
      this.replyTo = message
      this.preview = null
      //console.log('chateditor', this.$refs.chateditor)
      this.$refs.chateditor.editor.commands.focus()
    },
    previewFile(message, file) {
      console.log('previewFile', message, file)
      this.preview = { message, fileKey: file.key, user: this.getChatUser(message.user_key) }
    },
    nextPageFiles() {
      this.paginationFiles.current_page++
      this.loadChatFiles()
    },
    loadChatFiles(start = false) {
      this.loading.files = true
      ServiceChat.getMessages(this.chatActive.key, true, null, null, false, 'files', null)
        .then(response => {
          console.log('loadChatFiles', response)
          //append data to files
          if (start) {
            this.files = response.messages.reverse()
          } else {
            this.files = this.files.concat(response.messages.reverse())
          }
          this.paginationFiles = response.pagination
        })
        .finally(() => {
          this.loading.files = false
        })
    },
    closeRigthSidebar() {
      this.rightSidebar = null
    },
    toogleRightSidebar(toOpen) {
      this.rightSidebar = this.rightSidebar == toOpen ? null : toOpen
      this.loadData(this.rightSidebar)
    },
    unpromoteMessage(item) {
      console.log('unpromoteMessage', item)
      ServiceChat.unpromoteMessage(this.chatActive.key, item.key).then(response => {
        console.log('unpromoteMessage', response)
        this.loadAnouncements()
        this.chatActive.messages = this.chatActive.messages.map(element => {
          if (element.key === item.key) {
            element['announcement'] = response.announcement
          }
          return element
        })
        this.$buefy.snackbar.open({
          message: 'Mensagem despromovida com sucesso',
          type: 'is-success',
          position: 'is-bottom-right',
          duration: 5000
        })
      })
    },
    promoteMessage(item, duration) {
      console.log('promoteMessage', item, duration)
      let participants = Object.keys(this.allChatUsers).length - 1
      this.$buefy.dialog.confirm({
        title: 'Promover mensagem',
        message: `Deseja notificar todos os ${participants} participantes desta mensagem? Será enviada uma notificação por e-mail e na aplicação.`,
        confirmText: 'Enviar notificações',
        cancelText: 'Promover sem notificar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          if (item == null) {
            //is to send a new message
            this.addMessageToChannel(true)
          } else {
            ServiceChat.promoteMessage(this.chatActive.key, item.key, duration, true).then(response => {
              console.log('promoteMessage', response)
              this.loadAnouncements()
              this.chatActive.messages = this.chatActive.messages.map(element => {
                if (element.key === item.key) {
                  element['announcement'] = response.announcement
                }
                return element
              })
              this.$buefy.snackbar.open({
                message: 'Mensagem promovida com sucesso',
                type: 'is-success',
                position: 'is-bottom-right',
                duration: 5000
              })
            })
          }
        },
        onCancel: () => {
          if (item == null) {
            //is to send a new message
            this.addMessageToChannel(false)
          } else {
            ServiceChat.promoteMessage(this.chatActive.key, item.key, duration, false).then(response => {
              console.log('promoteMessage', response)
              this.loadAnouncements()
              //this.$emit('set-chat-messages', this.chatActive.key, [response.data], false, false, true)
              this.chatActive.messages = this.chatActive.messages.map(element => {
                if (element.key === item.key) {
                  element['announcement'] = response.announcement
                }
                return element
              })
              this.$buefy.snackbar.open({
                message: 'Mensagem promovida com sucesso',
                type: 'is-success',
                position: 'is-bottom-right',
                duration: 5000
              })
            })
          }
        }
      })
    },
    async scrollToMessage(msg) {
      this.preview = null
      console.log('scrollToMessage', msg)
      const chatContainer = document.getElementById('chat-messages')
      const message = chatContainer.querySelector(`.chat-message[data-key="${msg.key}"]`)

      if (message) {
        //add .show-message class to the message
        message.classList.add('show-message')
        message.scrollIntoView({
          behavior: 'smooth', // Smooth scroll
          block: 'center' // Scrolls to the start of the element
        })
        //remove .show-message class after 5 seconds
        setTimeout(() => {
          message.classList.remove('show-message')
        }, 5000)
      } else if (this.loadOldMessagesCore) {
        console.log('Message not found')
        await this.loadOldMessagesCore(this.chatActive.key)
        console.log('more messages loaded')
        setTimeout(() => {
          const chatContainer = document.getElementById('chat-messages')
          const message = chatContainer.querySelector(`.chat-message[data-key="${msg.key}"]`)
          if (message) {
            console.log('MESSAGE FOUND AFTER LOAD')
            this.scrollToMessage(msg)
          }
        }, 1000)
        /*let lastLoadedMessage = this.chatActive.messages[0]
        console.log('lastLoadedMessage', lastLoadedMessage)
        //get last message loaded
        //get message we want to scroll to //after, before
        console.log('loading more messages')
        ServiceChat.getMessages(this.chatActive.key, true, lastLoadedMessage.key).then(response => {
          console.log('scrollToMessage response', response)
          if (response.messages.length > 0) {
            //add before
            this.chatActive.messages = response.messages.reverse().concat(this.chatActive.messages)
            this.$nextTick(() => {
              //this.scrollToMessage(msg)
            })
          }
        })*/
      }
    },
    openAnouncement(key) {
      if (this.isMobile) {
        this.view = 'anouncements'
      } else {
        this.rightSidebar = 'anouncements'
      }
      setTimeout(() => {
        console.log('scrollToAnoucement', key)
        const chatContainer = document.getElementById('chat-anouncements')
        const message = chatContainer.querySelector(`.chat-message[data-key="${key}"]`)

        if (message) {
          //add .show-message class to the message
          message.classList.add('show-message')
          message.scrollIntoView({
            behavior: 'smooth', // Smooth scroll
            block: 'center' // Scrolls to the start of the element
          })
          //remove .show-message class after 5 seconds
          setTimeout(() => {
            message.classList.remove('show-message')
          }, 5000)
        } else {
          console.log('Anouncement not found')
        }
      }, 100)
    },
    showChatOptions(event) {
      const el = event.target.$el
      console.log('showChatOptions', el.$el)
      const relativePos = event.pos //relative position in relation to target: left, top, right, bottom
      const message = event.message
      const chatOptions = document.querySelector('.chat-options')
      if (chatOptions) {
        //get target bounding box
        const targetRect = el.getBoundingClientRect()
        chatOptions.style.display = 'block'
        chatOptions.style.top = targetRect.top + 'px'
        chatOptions.style.left = targetRect.left + 'px'
      }
      console.log('showChatOptions', relativePos, message, el.offsetTop, el.offsetLeft)
    },
    hideChatOptions() {
      //const chatOptions = document.querySelector('.chat-options')
      //if (chatOptions) chatOptions.style.display = 'none'
    },
    loadAnouncements() {
      this.loading.anouncements = true
      ServiceChat.getMessages(this.chatActive.key, true, null, null, false, 'announcements', 'active')
        .then(response => {
          console.log('loadAnouncements', response)
          this.anouncements = response.messages.reverse()
          this.currentAnouncement = 0
          if (this.anouncements.length > 0) {
            if (this.anouncementsTimer) clearInterval(this.anouncementsTimer)
            this.anouncementsTimer = setInterval(() => {
              if (this.pauseAnouncements) return
              this.currentAnouncement = this.currentAnouncement + 1
              if (this.currentAnouncement >= this.anouncements.length) {
                this.currentAnouncement = 0
              }
            }, 5000)
          }
        })
        .finally(() => {
          this.loading.anouncements = false
        })
    },
    unloadChat() {
      this.mobileShowChatsList = true
      this.mobileShowActiveChat = false
      this.$emit('unload-chat')
    },

    toggleFilter(type) {
      if (this.activeFilter && this.activeFilter === type) {
        this.activeFilter = null
      } else {
        this.activeFilter = type
      }
    },

    buildChatUser(chat) {
      return {
        name: chat.title,
        initials: chat.initials,
        photo: chat.icon
      }
    },

    activateShowNewGroup() {
      if (this.chatActive.key) this.$emit('unload-chat', this.chatActive.key, true)
      this.showNewGroup = true
      this.showPermissions = false
    },
    loadChat(chat) {
      this.view = 'messages'
      this.showNewGroup = false
      this.showPermissions = false
      this.$emit('load-chat', chat, false, false, this.chatLoadDone)

      // Make sure mobile works
      this.mobileShowActiveChat = true
      this.mobileShowChatsList = false
    },

    insertEmoji(emoji) {
      if (!this.chatActive.message) this.chatActive.message = ''
      if (this.chatActive.message.length) {
        this.chatActive.message = this.chatActive.message + '\u00a0' + emoji + '\u00a0'
      } else {
        this.chatActive.message = '' + emoji + '\u00a0'
      }
      this.editor.setContent(this.chatActive.message)
    },
    scrollToBottom() {
      const self = this
      ChatUtils.scrollToBottom(10, () => {
        self.chatActive.selfUnread = 0
      })
    },
    chatMessagesReady() {
      ChatUtils.scrollToBottom()
    },
    chatLoadDone() {
      this.loading.chat = false
    },
    chatUploadFileToMessage(files) {
      for (let fileEntry of files) {
        const key = fileEntry.response.data.file.key
        if (!this.chatActive.files.includes(key)) this.chatActive.files.push(key)
      }
      this.showFullDragZoneUploader = false
    },

    buildUnread(unread) {
      if (unread > 99) return '99'
      else if (unread > 0) return unread.toString()
      else return ''
    },

    getChatUser(userKey) {
      if (this.authUser.key == userKey) return this.authUser
      let user = this.allChatUsers[userKey]
      if (user) return user
      else return { name: userKey }
    },

    deleteChatMessage(chatKey, key) {
      //warning
      this.$buefy.dialog.confirm({
        title: 'Apagar mensagem',
        message: 'Tem a certeza que deseja <b>eliminar</b> esta mensagem?',
        confirmText: 'Apagar mensagem',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: () => {
          this.$emit('delete-chat-message', chatKey, key)
        }
      })
    },

    async sendMessage() {
      if (this.promoteSentMessage) {
        //ask if the user wants to notify all participants
        this.promoteMessage(null, this.announcementDuration)
      } else {
        this.addMessageToChannel()
      }
    },

    async addMessageToChannel(notify = false) {
      if (this.sendIsDisabled) return

      if (this.chatActive.files.length && !this.chatActive.message) {
        this.chatActive.message = 'Mensagem para partilha de ficheiros.'
      }

      this.sendingMessage = true
      try {
        const response = await ServiceChat.addMessage(
          this.chatActive.key,
          this.chatActive.message,
          this.chatActive.files,
          this.replyTo,
          this.promoteSentMessage,
          this.announcementDuration,
          notify
        )

        if (response.status == 200) {
          this.chatActive.message = null
          this.chatActive.files.splice(0, this.chatActive.files.length)
          this.$emit('set-chat-messages', this.chatActive.key, [response.data], false, true)
          if (this.replyTo) this.replyTo = null
          //reset announcement
          if (this.promoteSentMessage) this.loadAnouncements()
          this.promoteSentMessage = false
          this.announcementDuration = 'week'
        } else {
          // TODO
          this.$buefy.snackbar.open({
            message: `Não foi possível enviar a mensagem`,
            type: 'is-danger',
            position: 'is-bottom-right',
            duration: 5000
          })
        }
      } finally {
        this.sendingMessage = false
      }
    },

    async addNewGroup() {
      if (!this.newGroupTitle.length || this.loading.group) return
      this.loading.group = true

      try {
        const meeting = await ServiceMeetings.create(this.newGroupTitle)
        const chat = await ServiceChat.getChannel(meeting.chat.key)

        this.$emit('set-chat', chat, true, false, false, this.chatLoadDone, true)
        this.newGroupTitle = ''
        this.showNewGroup = false
        this.showPermissions = false
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        let message = `Não foi possível adicionar o email. Por favor, tente mais tarde.
            Caso o problema persista, contacte-nos através do nosso canal de suporte.`
        if (!errorKey) {
          message = 'Ocorreu um erro, por favor tente novamente mais tarde'
          console.error('Failed to add chat by email', error)
        }

        this.$buefy.snackbar.open({
          duration: 5000,
          message: message,
          type: 'is-danger',
          position: 'is-top-right',
          queue: false
        })
      } finally {
        this.loading.group = false
      }
    },
    async saveGroup() {
      if (this.chatActive.title) {
        await ServiceMeetings.update(this.chatActive.meeting.key, this.chatActive.title)
        this.chatActive.meeting.title = this.chatActive.title
        this.$emit('update-chat', this.chatActive.key, this.chatActive.title)
      } else {
        this.chatActive.title = this.chatActive.meeting.title
      }
    },
    showGroupPermissions() {
      this.showPermissions = true
      this.showNewGroup = false
    },
    async deleteGroup(key, meetingKey) {
      this.$buefy.dialog.confirm({
        title: 'Apagar grupo',
        message:
          'Tem a certeza que deseja <b>eliminar</b> este grupo? Todas as mensagens e ficheiros associados serão eliminados, bem como a sala virtual..',
        confirmText: 'Apagar grupo',
        type: 'is-danger',
        hasIcon: false,
        onConfirm: async () => {
          await ServiceMeetings.delete(meetingKey)
          this.$emit('remove-chat', key, this.chatLoadDone)
        }
      })
    },

    async addUser() {
      if (this.loading.email) return
      this.loading.email = true

      try {
        const chat = await ServiceChat.addUser(this.email)
        this.$emit('set-chat', chat, true, false, false, this.chatLoadDone, true)
        this.email = ''
      } catch (error) {
        const errorKey = utils.errors(error).getKey()
        let message = `Não foi possível adicionar o email. Por favor, tente mais tarde.
            Caso o problema persista, contacte-nos através do nosso canal de suporte.`

        if (!errorKey) {
          message = 'Ocorreu um erro, por favor tente novamente mais tarde'
          console.error('Failed to add chat by email', error)
        } else if (errorKey == 'ItemNotFound') {
          message = 'O email indicado não está registado.'
        } else if (errorKey == 'InvalidEmail') {
          message = 'O email não é válido.'
        } else if (errorKey == 'CannotAddYourself') {
          message = 'Não pode adicionar a sua conta.'
        }

        this.$buefy.snackbar.open({
          duration: 5000,
          message: message,
          type: 'is-danger',
          position: 'is-top-right',
          queue: false
        })
      } finally {
        this.loading.email = false
      }
    },

    addNewChannelModal() {
      this.$buefy.dialog.prompt({
        title: 'Novo canal',
        message: `Indique o email do utilizador institucional UC:`,
        inputAttrs: {
          type: 'email',
          placeholder: 'e.g. pessoa@uc.pt',
          maxlength: 100,
          min: 7
        },
        confirmText: 'Adicionar',
        cancelText: 'Cancelar',
        trapFocus: true,
        onConfirm: value => {
          this.email = value
          this.addUser()
        }
      })
    },

    addNewGroupModal() {
      this.$buefy.dialog.prompt({
        title: 'Novo grupo',
        message: `<div>Indique o nome do novo grupo que pretende criar.</div>
        <div class="text-sm">Mais tarde poderá alterar esta configuração nas preferências do grupo.</div>`,
        inputAttrs: {
          type: 'text',
          placeholder: 'e.g. O meu grupo',
          maxlength: 150,
          min: 2
        },
        confirmText: 'Adicionar',
        cancelText: 'Cancelar',
        trapFocus: true,
        onConfirm: value => {
          this.newGroupTitle = value
          this.addNewGroup()
        }
      })
    },

    async showAiTranslateModal() {
      let user = await ServiceAuth.getSelfUser(!this.aiTranslateLanguages.length)

      if (!this.aiTranslateLanguages.length) {
        for (const [key, value] of Object.entries(user.supported_languages)) {
          this.aiTranslateLanguages.push({ key: key, title: value })
        }
        this.aiTranslateLanguages.sort((a, b) => a.title.localeCompare(b.title))
      }

      this.aiTranslateLanguage = user.options.language
      this.aiTranslateOn = user.options.chat_show_translated
      this.isAiTranslateModalActive = true
    },
    aiTranslateLabel(key) {
      return this.aiTranslateLanguages.find(lang => lang.key == key).title
    },
    async updateOption(key, value) {
      const data = {}
      data[`option.${key}`] = value
      const user = await ServiceAuth.updateSelfUser(data)
      this.$store.dispatch('setUser', { user: user })
    },

    // Handle uploads
    dragover(event) {
      event.preventDefault()
      if (!this.showFullDragZoneUploader) {
        this.showFullDragZoneUploader = true
      }
    },
    dragleave() {
      this.showFullDragZoneUploader = false
    }
  }
}
</script>

<i18n lang="json">
{
  "pt": {
    "chat": {
      "messages": "Mensagens",
      "users": "Permissões",
      "buckets": "Ficheiros",
      "files": "Ficheiros",
      "settings": "Configurações"
    },
    "chat_messages": {
      "new_group": "Novo grupo",
      "new_group_title": "Indique o nome do novo grupo que pretende criar.",
      "new_group_placeholder": "e.g. O meu grupo",
      "new_group_add": "Adicionar",
      "new_group_cancel": "Cancelar",
      "new_group_message": "Mais tarde poderá alterar esta configuração nas preferências do grupo.",
      "add_user": "Adicionar utilizador",
      "add_user_email": "Indique o email do utilizador institucional UC:",
      "add_user_email_placeholder": "e.g. ucXXXXXXXX@uc.pt",
      "add_user_add": "Adicionar",
      "add_user_cancel": "Cancelar",
      "add_user_message": "O email indicado não está registado.",
      "add_user_invalid": "O email não é válido.",
      "add_user_cannot_add": "Não pode adicionar a sua conta.",
      "add_user_success": "Utilizador adicionado com sucesso.",
      "add_user_error": "Não foi possível adicionar o email. Por favor, tente mais tarde.",
      "add_user_error_contact": "Caso o problema persista, contacte-nos através do nosso canal de suporte.",
      "add_user_error_title": "Erro ao adicionar utilizador",
      "add_user_error_email": "Email inválido",
      "add_user_error_cannot_add": "Não pode adicionar a sua conta",
      "add_user_error_not_found": "Email não encontrado",
      "add_user_error_try_later": "Tente novamente mais tarde",
      "add_user_error_contact_support": "Contacte-nos através do nosso canal de suporte",
      "add_user_error_message": "Não foi possível adicionar o email. Por favor, tente mais tarde. Caso o problema persista, contacte-nos através do nosso canal de suporte."
    },
    "send_as_announcement": "Enviar como anúncio",
    "send_as_message": "Enviar como mensagem"
  },
  "en": {
    "chat": {
      "messages": "Messages",
      "users": "Permissions",
      "buckets": "Files",
      "files": "Files",
      "settings": "Settings"
    },
    "chat_messages": {
      "new_group": "New group",
      "new_group_title": "Enter the name of the new group you want to create.",
      "new_group_placeholder": "e.g. My group",
      "new_group_add": "Add",
      "new_group_cancel": "Cancel",
      "new_group_message": "You can change this setting later in the group preferences.",
      "add_user": "Add user",
      "add_user_email": "Enter the email of the UC institutional user:",
      "add_user_email_placeholder": "e.g. ucXXXXXXXX@uc.pt",
      "add_user_add": "Add",
      "add_user_cancel": "Cancel",
      "add_user_message": "The indicated email is not registered.",
      "add_user_invalid": "The email is not valid.",
      "add_user_cannot_add": "You cannot add your account.",
      "add_user_success": "User added successfully.",
      "add_user_error": "Could not add the email. Please try again later.",
      "add_user_error_contact": "If the problem persists, contact us through our support channel.",
      "add_user_error_title": "Error adding user",
      "add_user_error_email": "Invalid email",
      "add_user_error_cannot_add": "You cannot add your account",
      "add_user_error_not_found": "Email not found",
      "add_user_error_try_later": "Try again later",
      "add_user_error_contact_support": "Contact us through our support channel",
      "add_user_error_message": "Could not add the email. Please try again later. If the problem persists, contact us through our support channel."
    },
    "send_as_announcement": "Send as announcement",
    "send_as_message": "Send as message"
  }
}
</i18n>

<template>
  <div>
    <div>
      <fw-label size="xs">Controlo de câmara</fw-label>
    </div>
    <div class="relative flex gap-4 bg-gray-900 bg-opacity-90 rounded-lg p-3 justify-around">
      <fw-button type="link-muted" @click.native="showModal = true">
        Plateia
      </fw-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PanelPtzControl',

  data() {
    return {
      showModal: false
    }
  },

  computed: {},

  mounted() {},

  methods: {}
}
</script>
